import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function stockbaseUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    // { key: 'stock_id', label: 'ID', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    // { key: 'warehouse_name', label: '仓库名称', sortable: true },
    // { key: 'stock_no', label: '库存编号', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    // { key: 'name', label: '名称', sortable: true },
    // { key: 'is_gift', label: '是否赠品', sortable: true },
    // { key: 'batch_no', label: '产品批次', sortable: true },
    // { key: 'production_date', label: '生产日期', sortable: true },
    // { key: 'expiry_date', label: '有效日期', sortable: true },
    // { key: 'company_id', label: '采购主体', sortable: true },
    // { key: 'purchaseorder_ItemId', label: '采购单明细ID', sortable: true },
    // { key: 'cost', label: '采购单含税单价', sortable: true },
    // { key: 'location_id', label: '仓位ID', sortable: true },
    // { key: 'location_fullname', label: '仓位全称', sortable: true },
    // { key: 'qty', label: '数量', sortable: true },
    // { key: 'qty_lock', label: '锁库数量', sortable: true },
    // { key: 'status', label: '库存状态 stock_status', sortable: true },
    // { key: 'add_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
    // { key: 'product_company_id', label: '货物主体', sortable: true },
    // { key: 'purchaseorder_id', label: '采购单ID', sortable: true },
    //     { key: 'actions', label: '操作' },
    { key: 'warehouse_id', label: '仓库'},
    { key: 'company_id', label: '货主'},
    { key: 'code', label: '商品69码'},
    { key: 'name', label: '商品名称'},
    { key: 'qty', label: '库存数量', sortable: true },
    { key: 'all_cost', label: '库存金额', sortable: true },
    { key: 'status', label: '状态'},
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('stock_id')
  const isSortDirDesc = ref(true)
  const condition = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    console.log(condition)
    store
      .dispatch('stockbase/searchList', {
        warehouseId: condition.value.searchWarehouseId,
        productId: condition.value.searchProductId,
        stockId: condition.value.stockId,
        addTime: condition.value.addTime,
        purchaseTeamId: isEmpty(condition.value.purchaseTeamId)?null:condition.value.purchaseTeamId.value,
        start: start.value,
        limit: limit.value,
        orderBys: orderBy.value,
        orderDescs: isSortDirDesc.value === true ? 'desc' : '',
        order_by: 'product_id',
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,

    // Extra Filters
  }
}
