<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            md="6"-->
          <!--          >-->
          <!--            <div class="d-flex align-items-center justify-content-end">-->
          <!--              <b-form-input-->
          <!--                v-model="searchKey"-->
          <!--                class="d-inline-block mr-1"-->
          <!--                placeholder="搜索关键字..."-->
          <!--              />-->
          <!--              <b-button-->
          <!--                variant="primary"-->
          <!--                :to="{ name: 'apps-stockbase-edit'}"-->
          <!--              >-->
          <!--                <span class="text-nowrap">添加</span>-->
          <!--              </b-button>-->
          <!--            </div>-->
          <!--          </b-col>-->

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>

        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="仓库"
                    type="input"
                    v-on:change="fromChildren($event,['searchWarehouseName','searchWarehouseId'])"
                    :params="['searchWarehouseName','searchWarehouseId']"
                    :value="condition.searchWarehouseName"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                >
                </xy-input-button>
              </b-col>

              <!-- Search 仓库、商品名称、商品69码 -->
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="商品"
                    type="input"
                    v-on:change="fromChildren($event,['searchProductName','searchProductId'])"
                    :params="['searchProductName','searchProductId']"
                    :value="condition.searchProductName"
                    modalName="商品"
                    placeholder="点击选择商品"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="库存id:"
                    label-for="stockIds"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      v-model="condition.stockId"
                      class="d-inline-block mr-1"
                      placeholder="输入库存id"
                      type="number"
                  />

                </b-form-group>
              </b-col>

              <b-col md="4">
                <xy-input-button label="截止日期"
                                 type="data"
                                 v-on:change="fromChildren($event,['addTime'])"
                                 :params="['addTime']"
                                 :value="condition.addTime"
                >
                </xy-input-button>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="销售权:"
                        label-for="status"
                        label-size="sm"
                >
                  <v-select
                          :options="getCodeOptions('purchase_team')"
                          v-model="condition.purchaseTeamId"
                          name="status"
                          class="select-size-sm"
                          placeholder="请选择销售权"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="createExcel"
                >
                  <span class="align-right">导出</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.item" :fields="fields">

                <template #cell(company_id)="data">
                  {{ getCodeLabel("company", data.item.company_id) }}
                </template>

                <template #cell(status)="data">
                  <b-badge
                      pill
                      :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
                  >
                    {{ getCodeLabel("stock_status", data.item.status) }}
                  </b-badge>
                </template>
                <template #cell(location_fullname)="data">
                  <b-badge
                      pill
                      :variant="`light-${getCodeColor('warehouse_location',data.value)}`"
                  >
                    {{ getCodeLabel('warehouse_location', data.value) }}
                  </b-badge>
                </template>

                <template #cell(creator)="data">
                  {{ getCodeLabel("user", data.item.creator) }}
                </template>

                <template #cell(name)="data">
                  {{data.item.name}}{{ data.item.is_gift===1?"(赠)":"" }}
                </template>

                <template #cell(add_time)="data">
                  {{ toDate(data.item.add_time) }}
                </template>

                <template #cell(qty)="data">
                  库存：{{ getCodeLabel('setting','start_inventory')!=='1'?data.item.qty:0 }}
                  / 小计: {{data.item.ext==undefined?'':data.item.ext.subtotal}}
                  <feather-icon
                      icon="TwitchIcon"
                      size="21"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.html="
                              '<div class=text-left>'+
                              '库存编号：'+data.item.stock_no+
                              '<br>'+'产品批次：'+data.item.batch_no+
                              '<br>'+'开始：'+toDate(data.item.production_date)+
                              '<br>'+'结束：'+toDate(data.item.expiry_date)+
                              '<br>'+'成本：'+data.item.cost+
                              '</div>'"
                      variant="success"
                  />
                </template>

                <template #cell(productcode)="data">
                  {{ data.item.ext==undefined?'':data.item.ext.productcode }}
                </template>

                <template #cell(boxquantity)="data">
                  {{ data.item.ext==undefined?'':data.item.ext.boxquantity }}
                </template>

                <template #cell(productunit)="data">
                  {{ data.item.ext==undefined?'':getCodeLabel("product_unit", data.item.ext.productunit) }}
                </template>

                <template #cell(productunit)="data">
                  {{ data.item.ext==undefined?'':getCodeLabel("product_unit", data.item.ext.productunit) }}
                </template>
                <!--                <template #cell(ext)="data">-->
                <!--                  {{ data.item.ext.code ? data.item.ext.code : '' }}-->
                <!--                </template>-->

                <!--                <template #cell(name)="data">-->
                <!--                  [#{{ data.item.product_id }}]{{ data.item.name }}-->
                <!--                </template>-->

                <!--                &lt;!&ndash;库存转态&ndash;&gt;-->
                <!--                <template #cell(status)="data">-->
                <!--                  <b-badge-->
                <!--                      pill-->
                <!--                      :variant="`light-${getCodeColor('stock_status', data.item.status)}`"-->
                <!--                  >-->
                <!--                    {{ getCodeLabel("stock_status", data.item.status)}}-->
                <!--                  </b-badge>-->
                <!--                </template>-->

                <!--                <template #cell(gross_profit_rate)="data">-->
                <!--                  {{ data.item.ext.gross_profit_rate ? data.item.ext.gross_profit_rate : ''}}-->
                <!--                </template>-->

              </b-table>
            </div>
          </b-card>
        </template>

        <template #cell(qty)="data">
          库存 : {{ getCodeLabel('setting','start_inventory')!=='1'?data.item.qty:0 }}
        </template>
        <!--        仓库-->
        <template #cell(warehouse_id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          {{ getCodeLabel('warehouse', data.item.warehouse_id) }}
        </template>

        <!--        货主-->
        <template #cell(company_id)="data">
          {{ getCodeLabel("company", data.item.company_id) }}
        </template>

        <template #cell(name)="data">
          [#{{ data.item.product_id }}]{{ data.item.name }}
        </template>

        <template #cell(code)="data">
          {{data.item.ext==undefined?'无':data.item.ext.item[0]==undefined?'无':data.item.ext.item[0].ext.productcode}}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
          >
            {{ getCodeLabel("stock_status", data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--单菜单-->
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-stockbase-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-stockbase-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  second,
    isEmpty
} from '@core/utils/filter'
import stockbaseUseList from './stockbaseUseList'
import stockbaseStore from './stockbaseStore'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import ProductSelect from '@/views/apps/product/product-salesorder/ProductSelect'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    WareHouseSelect,
    ProductSelect,
    XyInputButton,
    isEmpty,
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('stockbase/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  data() {
    return {
      fields: [
        {key: 'stock_id', label: 'ID', sortable: true},
        {key: 'warehouse_name', label: '仓库', sortable: true},
        {key: 'company_id', label: '货主', sortable: true},
        {key: 'productcode', label: '69码',},
        {key: 'name', label: '商品', sortable: true},
        {key: 'productunit', label: '单位'},
        {key: 'location_fullname', label: '库位', sortable: true},
        {key: 'qty', label: '库存数量', sortable: true},
        {key: 'status', label: '状态', sortable: true},
        {key: 'add_time', label: '时间', sortable: true},
        {key: 'creator', label: '入库人', sortable: true},
        // {key: 'actions', label: '操作'},
      ],
      advanced_search_modal: false,
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stockbase')) store.registerModule('stockbase', stockbaseStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockbase')) store.unregisterModule('stockbase')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const searchByCondition = function () {
      if (this.condition.addTime!=undefined){
        if (second(this.condition.addTime)<1643644800){
          toast.error("日期必须大于2022-02-01!")
          return ;
        }
      }

      refetchData()
    }

    const resetCondition = function () {
      this.condition= {}
      refetchData()
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const createExcel = function () {
      axios({
            url: '/api/exportexcelfile/createStockBaseExcel',
            params:{
              warehouseId: condition.value.searchWarehouseId,
              productId: condition.value.searchProductId,
              stockId: condition.value.stockId,
              addTime: condition.value.addTime,
              purchaseTeamId: isEmpty(condition.value.purchaseTeamId)?null:condition.value.purchaseTeamId.value,
            },
            method:'get',
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "stock-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = stockbaseUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      second,

      condition,
      advanced_search,
      searchByCondition,
      resetCondition,
      fromChildren,
      createExcel,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
